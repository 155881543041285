import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import Layout from "../../../components/layout";
import WithProviders from "../../../components/WithProviders";
import { Message } from "../../../lib/getMessage";

import "./style.css";
import HeroBanner from "../../../components/HeroBanner";

import ServiceDelPrtnrDesc from "../../images/srvc-delvry-prtnr-desc.png";
import ServiceDelPrtnrDesc2 from "../../images/srvc-delvry-prtnr-desc@2x.png";
import ServiceDelPrtnrDesc3 from "../../images/srvc-delvry-prtnr-desc@3x.png";
import ProductWithImage from "../../../components/ProductWithImage";

import ServiceDelPrtnrBotmFirst from "../../images/srvc-delvry-prtnr-intg-first.png";
import ServiceDelPrtnrBotmFirst2 from "../../images/srvc-delvry-prtnr-intg-first@2x.png";
import ServiceDelPrtnrBotmFirst3 from "../../images/srvc-delvry-prtnr-intg-first@3x.png";

import ServiceDelPrtnrBotmFirstMob from "../../images/srvc-delvry-prtnr-intg-first-mob.png";
import ServiceDelPrtnrBotmFirstMob2 from "../../images/srvc-delvry-prtnr-intg-first-mob@2x.png";
import ServiceDelPrtnrBotmFirstMob3 from "../../images/srvc-delvry-prtnr-intg-first-mob@3x.png";

import ServiceDelPrtnrBotmSec from "../../images/srvc-delvry-prtnr-intg-sec.png";
import ServiceDelPrtnrBotmSec2 from "../../images/srvc-delvry-prtnr-intg-sec@2x.png";
import ServiceDelPrtnrBotmSec3 from "../../images/srvc-delvry-prtnr-intg-sec@3x.png";

import ServiceDelPrtnrBotmSecMob from "../../images/srvc-delvry-prtnr-intg-sec-mob.png";
import ServiceDelPrtnrBotmSecMob2 from "../../images/srvc-delvry-prtnr-intg-sec-mob@2x.png";
import ServiceDelPrtnrBotmSecMob3 from "../../images/srvc-delvry-prtnr-intg-sec-mob@3x.png";

import TriangleIcon from "../../images/right-triangle.svg";

const DeliveryPartnerIntgration = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window?.innerWidth < 480) {
      setIsMobile(true);
    }
  }, []);
  const { language, updateLanguage, direction, location } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={location}
    >
      <div>
        <Header
          current="service"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="srvc-delvry-prtnr">
          <HeroBanner
            title={
              <Message dictKey="services.deliverypartnerintegration.title" />
            }
            className="delvry-prtnr-banner"
          />
          <section className="srvc-delvry-prtnr-desc">
            <ProductWithImage
              name="delvry-prtnr-desc"
              className="delvry-prtnr-desc"
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="services.deliverypartnerintegration.description1" />
                  ),
                  id: 1,
                },
                {
                  type: "p",
                  val: (
                    <Message dictKey="services.deliverypartnerintegration.description2" />
                  ),

                  id: 2,
                },
              ]}
              image={{
                normal: ServiceDelPrtnrDesc,
                xx: ServiceDelPrtnrDesc2,
                xxx: ServiceDelPrtnrDesc3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </section>
          <section className="srvc-delvry-prtnr-intg">
            <div className="header">
              <h2>
                <Message dictKey="services.deliverypartnerintegration.header.title" />
              </h2>
            </div>
            <div className="media">
              {isMobile ? (
                <img
                  src={ServiceDelPrtnrBotmFirstMob}
                  srcSet={`${ServiceDelPrtnrBotmFirstMob2} 2x, ${ServiceDelPrtnrBotmFirstMob3} 3x`}
                  alt="media"
                  loading="lazy"
                />
              ) : (
                <img
                  src={ServiceDelPrtnrBotmFirst}
                  srcSet={`${ServiceDelPrtnrBotmFirst2} 2x, ${ServiceDelPrtnrBotmFirst3} 3x`}
                  alt="media"
                  loading="lazy"
                />
              )}
            </div>
            <div className="middle">
              <img
                className="triangle-icon"
                src={TriangleIcon}
                alt="triangle-svg"
                loading="lazy"
              />
            </div>
            <div className="header">
              <h2>
                <Message dictKey="services.deliverypartnerintegration.header.title2" />
              </h2>
            </div>
            <div className="media">
              {isMobile ? (
                <img
                  src={ServiceDelPrtnrBotmSecMob}
                  srcSet={`${ServiceDelPrtnrBotmSecMob2} 2x, ${ServiceDelPrtnrBotmSecMob3} 3x`}
                  alt="media"
                  loading="lazy"
                />
              ) : (
                <img
                  src={ServiceDelPrtnrBotmSec}
                  srcSet={`${ServiceDelPrtnrBotmSec2} 2x, ${ServiceDelPrtnrBotmSec3} 3x`}
                  alt="media"
                  loading="lazy"
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(DeliveryPartnerIntgration);
